import React from "react"
import { graphql } from "gatsby"

import ContentList from "../components/content_list"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({
  pageContext,
  data
}) {
  const { tag } = pageContext
  const nodes = [
    ...data.allPrismicApp.edges,
    ...data.allPrismicPost.edges,
  ].map(e => e.node);
  return (
    <Layout>
      <SEO title={tag} />
      <ContentList nodes={nodes} showLabel={true} title={`${tag} (${nodes.length})`} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String!) {
    allPrismicApp(filter: {data: {tags: {elemMatch: {tag: {in: [$tag]}}}}}) {
      edges {
        node {
          id
          type
          uid
          data {
            name {
              text
            }
            summary {
              text
            }
            tags {
              tag
            }
          }
        }
      }
    }
    allPrismicPost(filter: {data: {tags: {elemMatch: {tag: {in: [$tag]}}}}}) {
      edges {
        node {
          id
          type
          uid
          data {
            date(formatString:"MMMM Do, YYYY")
            title {
              text
            }
            tags {
              tag
            }
            summary {
              text
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`