import PropTypes from "prop-types"
import React from "react"

import AppPost from "./app_post"
import BlogPost from "./blog_post"

const ContentList = ({ nodes, title, showLabel }) => (
  <>
    <h1>{title}</h1>
    {nodes.map((node, i) => (
      <div key={i} className="pure-u-1-1">
        {node.type === 'app' ? <AppPost app={node} showLabel={showLabel} /> : <BlogPost post={node} showLabel={showLabel} />}
      </div>
    ))}
  </>
)

ContentList.propTypes = {
  nodes: PropTypes.array,
  title: PropTypes.string,
  showLabel: PropTypes.bool,
}

ContentList.defaultProps = {
  nodes: [],
  title: '',
  showLabel: false,
}

export default ContentList
